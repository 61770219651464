/*--------------------------------
    Brands Area  
------------------------------*/

.eduvibe-home-five-brands {
  .client-logo {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transition: all 0.4s;
    display: inline-block;
    @media #{$small-tablet} {
      margin-bottom: 30px;
    }
    // &:hover .logo-main,
    // & .logo-hover {
    //     display: none;
    // }
    // &:hover .logo-hover {
    //     display: block !important;
    // }
    img {
      display: flex;
    }
  }
}
