.waitlist-wrap {
  width: 100%;
  display: flex;
}
.waitlist-left {
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 50px;
}
.waitlist-left h4 span {
  color: #385aa2;
}
.waitlist-right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waitlist-right form {
  width: 90%;
  display: grid;
  gap: 20px;
}
.waitlist-right form input {
  width: 100%;
  padding: 1rem;
}
.waitlist-right form button {
  width: 100% !important;
}

@media only screen and (max-width: 575px) {
  .waitlist-wrap {
    display: flex;
    flex-direction: column;
  }
  .waitlist-left {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
  }
  .waitlist-left h4 {
    text-align: center;
  }
  .waitlist-right {
    width: 100%;
  }
}
