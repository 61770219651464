.new-testimonial-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;

  .new-testimonial-wrap {
    padding-top: 68px;
    padding-bottom: 28px;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
      text-align: center;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 50px */
      background: linear-gradient(211deg, #00008b 0%, #f5bd00 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      width: 80%;
      text-align: center;
      color: #00008b;
      font-size: 30px;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 1.2px;
    }
    span {
      color: #00008b;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: 1.2px;
    }

    .new-testimonial-focused-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .new-testimonial-focused-content {
        width: 55%;
        display: flex;
        align-items: center;
        border-radius: 30px;
        background: #00008b;
        padding: 1rem 0 1rem 3rem;

        .new-testimonial-focused-text {
          width: 60%;
          align-items: flex-start;
          flex-direction: column;
          gap: 2rem;

          h5 {
            width: 150%;
            text-align: start;
            color: #f5bd00;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 40px */
          }

          p {
            width: 120%;
            text-align: start;
            color: #fff;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 41.6px */
            letter-spacing: 1.28px;
          }

          a {
            width: 270px !important;
            display: flex;
            padding: 12px 37px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border: 4px solid #fff;
            background: #f5bd00;
            color: #00008b;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
          }
        }

        .new-testimonial-focused-img {
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: auto;
            transform: translateX(50%);
          }
        }
      }
      .new-testimonial-focused-list {
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 4rem 0;

        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          list-style: none;
          justify-content: space-around;
          align-items: flex-start;

          li {
            width: 100%;
            border-radius: 30px;
            background: #00008b;
            text-align: start;
            padding-left: 30%;
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 26px */
            letter-spacing: 0.8px;
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}
