/* calendarly react */
.react-calendar {
  width: 100%;
  float: left;
  height: 100%;
}

.react-calendar__month-view__days {
  height: 260px;
}

.react-calendar__navigation {
  width: 97%;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #27259c;
  padding-bottom: 20px;
}

.react-calendar__navigation__label {
  color: #27259c;
}

.react-calendar__navigation__prev-button {
  border: none;
  color: #27259c;
}
.react-calendar__navigation__next-button {
  color: #27259c;
}

.react-calendar__navigation__prev2-button {
  color: #27259c;
}
.react-calendar__navigation__next2-button {
  color: #27259c;
}

/* .react-calendar__month-view__weekdays {
} */

.react-calendar__navigation button {
  font-size: 1.2em;
}

.react-calendar__month-view__weekdays__weekday {
  border: none;
  width: 100% !important;
  text-align: center;
}
.react-calendar__year-view__months__month {
  color: #27259c;
}
.react-calendar__decade-view__years__year {
  color: #27259c;
}
.react-calendar__century-view__decades__decade {
  color: #27259c;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #27259c;
}
.react-calendar__month-view__weekdays {
  color: #27259c;
}

.react-calendar__month-view__days__day {
  border: none;
  background-color: white;
  padding: 10px;
  font-size: 16px;
  color: #27259c;
}

.react-calendar__tile {
  padding: 15px;
}
.react-calendar__tile--active {
  /* background: #eef5fe; */
  background: #27259c;
  border-radius: 30px;
  line-height: 10px;
  /* color: #0e70fb; */
  color: #eef5fe;
  cursor: pointer;
}

/* .react-calendar--selectRange  */
.react-calendar__tile--hover {
  /* background: #006edc; */
  background: #27259c;
  border-radius: 30px;
  line-height: 10px;
  color: white;
  cursor: pointer;
}

.react-calendar button:enabled:focus {
  /* background: #006edc; */
  background: #27259c;
  border-radius: 30px;
  line-height: 10px;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 400px) {
  .react-calendar__tile {
    padding: 12px;
  }
  .react-calendar__navigation button {
    font-size: 1em;
  }
  .react-calendar__month-view__days__day {
    border: none;
    background-color: white;
    padding: 10px;
    font-size: 13px;
    color: #27259c;
  }
  .react-calendar__navigation {
    width: 99%;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #27259c;
    padding-bottom: 0;
  }
  .react-calendar__month-view__weekdays__weekday {
    font-size: 12px !important;
  }
  .react-calendar__month-view__days {
    height: 240px !important;
    font-size: 12px !important;
    width: 100% !important;
  }
  button {
    padding: 0px;
  }
  .react-calendar__month-view__days__day {
    font-size: 12px !important;
  }
  .setTime {
    font-size: 14px !important;
    padding: 10px, 4px;
    font-weight: 500;
    margin-bottom: 3px !important;
  }
}
/* select date css */
.selected-date-main-div {
  width: 100vw;
  display: flex;
  align-items: center;
}

.outerdiv-selectedDate {
  width: 80%;
  height: 670px;
  display: grid;
  grid-template-columns: 30% 70%;
  margin-top: 70px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 20px 20px 50px #e9e9e9;
  /* font-family: Arial, Helvetica, sans-serif; */
}
.right-date-selection-bar {
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  border-top: 1px solid lightgray;
}

#back-button-selectedDate {
  border-radius: 30px;
  line-height: 40px;
  /* color: #0e70fb; */
  color: #27259c;
  cursor: pointer;
  width: 43px;
  /* height: 30px; */
  border: 1px solid lightgray;
  background-color: white;
  font-size: 18px;
  font-weight: 100;
}
.left-container-selectedDate {
  width: 95%;
  margin-left: 5px;
  margin-top: 20px;
  border-right: 1px solid #e9e9e9;
}

.right-container-selectedDate {
  width: 100%;
  margin-top: 50px;
}

.rightmost-popup-selectedDate {
  width: 100%;
  height: 100%;
  padding-right: 5px;
  margin-left: 5px;
}

.selected-date {
  color: rgb(68, 68, 68);
  padding: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.3rem;
}

#momentDate-selectedDate {
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: rgb(240, 9, 47); */
  color: black;
}

.button-container {
  max-height: 350px;
  overflow: auto;
}

.setTime {
  font-family: Arial, Helvetica, sans-serif;
  padding: 15px 5px;
  background-color: white;
  border: 0.5px solid #27259c;
  width: 85%;
  border-radius: 4px;
  color: #27259c;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.setTime:hover {
  /* border: 2px solid #0e70fb; */
  border: 2px solid #27259c;
}

.newSetTime {
  font-family: Arial, Helvetica, sans-serif;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  border: 0.5px solid rgba(0, 0, 0, 0.6);
  width: 80%;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.display-none {
  display: none;
}

.available-onClick {
  font-family: Arial, Helvetica, sans-serif;
  padding: 15px;
  color: white;
  /* border: .5px solid #67a6ff; */
  border: 0.5px solid #27259c;
  justify-self: center;
  width: 60%;
  border-radius: 4px;
  /* background-color: #0e70fb; */
  background-color: #27259c;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.available-onClick:hover {
  background-color: #191783;
  font-size: 18px;
}

.unavailable-onClick {
  font-family: Arial, Helvetica, sans-serif;
  padding: 15px;
  color: white;
  justify-self: center;
  width: 60%;
  border-radius: 4px;
  border: 2px solid #27259c;
  background-color: #27259c;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: "none" !important;
  opacity: 0.7;
  transform: none;
}
/* filter */
.time-zone-filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 992px) {
  .time-zone-filter {
    align-items: flex-start;
  }
}
@media only screen and (max-width: 600px) {
  .earnings-close-modal {
    margin: 1rem !important;
  }
  .outerdiv-selectedDate {
    width: 95%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 70px;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 20px 20px 50px #e9e9e9;
    /* font-family: Arial, Helvetica, sans-serif; */
  }
  .right-container-selectedDate {
    width: 100%;
    height: 400px;
  }
}

/* loading and strip */
.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-bottom: 32px;
}
button {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 5px 5px;
  /* margin-top: 16px; */
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
button:hover {
  filter: contrast(115%);
}
button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled {
  opacity: 0.5;
  cursor: none;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: undefined;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: undefined;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.loading-bar {
  padding: 0.5rem;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: #ebf1fe;
  color: #2a7ade;
  margin-right: 5vw;
  margin-left: 5vw;
  text-align: center;
}
.error-bar {
  padding: 0.5rem;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: #fff0f1;
  color: #d95087;
  margin-right: 5vw;
  margin-left: 5vw;
  text-align: center;
}
.success-bar {
  padding: 0.5rem;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: #e5faf2;
  color: #3bb077;
  margin-right: 5vw;
  margin-left: 5vw;
  text-align: center;
}
.warning-bar {
  padding: 0.5rem;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: #fbeee242 !important;
  color: #b08f3b;
  margin-right: 5vw;
  margin-left: 5vw;
  text-align: center;
}
.earnings-close-modal {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem;
  font-size: 3rem;
  height: 5rem;
  width: 5rem;
  background-color: #fff;
  color: rgb(182, 6, 6);
  transition: 0.3s all ease-in-out;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 23px 0px rgba(211, 211, 219, 0.75);
  -webkit-box-shadow: 0px 0px 23px 0px rgba(211, 211, 219, 0.75);
  -moz-box-shadow: 0px 0px 23px 0px rgba(211, 211, 219, 0.75);
}
.earnings-close-modal:hover {
  font-size: 3.5rem;
}

/* next arrow css */
.next_arrow_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3rem;
}
.next_arrow_icon {
  display: block;
  font-size: 4rem !important;
}
@media only screen and (max-width: 575px) {
  .next_arrow_icon {
    display: none;
  }
}

/* expert image height css */
.exprt-image {
}
@media only screen and (max-width: 1900px) {
  .exprt-image {
    height: 280px !important;
  }
}
@media only screen and (max-width: 1650px) {
  .exprt-image {
    height: 270px !important;
  }
}
@media only screen and (max-width: 1199px) {
  .exprt-image {
    height: 250px !important;
  }
}
@media only screen and (max-width: 991px) {
  .exprt-image {
    height: 210px !important;
  }
}
@media only screen and (max-width: 767px) {
  .exprt-image {
    height: 210px !important;
  }
}
@media only screen and (max-width: 575px) {
  .exprt-image {
    height: 200px !important;
  }
}
@media only screen and (max-width: 479px) {
  .exprt-image {
    height: 170px !important;
  }
}
@media only screen and (max-width: 385px) {
  .exprt-image {
    height: 150px !important;
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
