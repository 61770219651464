.new-subscribe-section {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;

  .new-subscribe-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;

    h4 {
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 40px */
      background: linear-gradient(211deg, #00008b 0%, #f7d101 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    form {
      width: 70%;
      min-width: 300px;
      display: flex;
      align-items: center;

      input {
        border: 4px solid #00008b;
        border-radius: 0px !important;
        background: #fff;
        width: 70%;
        height: 70px;
      }
      button {
        border: 4px solid #00008b;
        background: #00008b;
        width: 30%;
        height: 70px;
        color: #fff;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
      }
    }
  }
}
