.new-discover-section {
  width: 100vw;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 140px;
  padding-bottom: 140px;

  .new-discover-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 130px;

    h1 {
      text-align: center;
      font-size: 45px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 55px */
      text-transform: uppercase;
      background: linear-gradient(211deg, #00008b 0%, #f5bd00 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .new-discover-cards-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .new-discover-card {
        max-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        display: flex;
        padding: 24px 0px;
        flex-direction: column;
        gap: 24px;
        background: #00008b;
        opacity: 0.800000011920929;
        box-shadow: 0px 8px 8px 0px rgba(0, 0, 139, 0.25);
        border-radius: 30px;

        h5 {
          text-align: center;
          font-size: 26px;
          font-weight: 700;
          color: #fff;
          margin-bottom: 0!important;
        }

        h6 {
          text-align: center;
          font-size: 20px;
          margin: 0px 24px;
          font-weight: 700;
          color: #fff;
          margin-bottom: 0!important;
        }

        a {
          border-radius: 8px;
          background: #f5bd00;
          width: 80%;
          padding: 8px;
          color: #000;
          font-size: 22px;
          font-weight: 700;
          text-align: center;

        }


        img {
          width: 100%;
        }
      }
    }
  }
}
