.new-hero-section {
  width: 100vw;
  background: #00008b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 200px;

  .new-hero-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new-hero-left {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    
      gap: 26px;

      h1 {
        font-size: 70px !important;
        font-weight: 600;
        background: linear-gradient(211deg, #f5bd00 0%, #fff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 0 !important;
      }

      h2 {
        font-size: 70px !important;
        font-weight: 600;
        color: #fff;
        margin-top: -4rem !important;
        margin-bottom: 0 !important;
      }

      p {
        color: #fff;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    
      }
      .new-hero-btn {
        border: 4px solid #fff;
        background: #f5bd00;
        display: inline-flex;
        padding: 18px 47px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #00008B;
        font-size: 30px;
        font-weight: 600;
      }
    }

    .new-hero-right {
      width: 40%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
