/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;900&display=swap');

body{
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    background-color:#fbfcfd; 
} */
.outerdiv-meeting {
  width: 100%;
  height: 100%;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: 30% 70%;
  margin-top: 60px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 0px 23px 0px rgba(211,211,219,0.75);
  -webkit-box-shadow: 0px 0px 23px 0px rgba(211,211,219,0.75);
  -moz-box-shadow: 0px 0px 23px 0px rgba(211,211,219,0.75);
  /* font-family: Arial, Helvetica, sans-serif; */
}

.left-container-meeting {
  width: 97%;
  padding-left: 2rem;
  border-right: 1px solid #e9e9e9;
  padding-top: 26px;
}

.back-button {
  border-radius: 30px;
  line-height: 40px;
  /* color: #0e70fb; */
  color: #27259c;
  cursor: pointer;
  width: 43px;
  border: 1px solid lightgray;
  background-color: white;
  font-size: 18px;
  font-weight: 100;
}

.back-button:hover {
  background-color: #f5d0c4;
}

#event-string-p {
  /* color: #5ac8a4; */
  color: #767676;
}

#time-zone {
  color: #767676;
}

.right-container-meeting {
  width: 100% !important;
  padding: 0 !important;
  margin-top: 13px;
}

.meetingp {
  /* color: red; */
  font-size: 18px;
}

.input-container-meeting {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.meeting-label {
  font-size: 13px;
  padding-bottom: 10px;
}

.input-meeting {
  height: 43px;
  width: 90%;
  border-radius: 8px;
  border: 1px solid lightgray;
  outline: none;
  margin-bottom: 5px;
  padding-left: 12px;
  font-size: 15px;
  font-weight: 100;
  color: #383838;
}
.input-meeting:focus {
  /* border: 2px solid #0e70fb; */
  border: 2px solid #27259c;
}

.input-meeting-error {
  height: 43px;
  width: 90%;
  border-radius: 8px;
  border: 1px solid #c96161;
  outline: none;
  margin-bottom: 5px;
  padding-left: 12px;
  font-size: 15px;
  font-weight: 100;
  color: #383838;
}
.input-meeting-error-hidden {
  height: 20px;
  width: 90%;
  border-radius: 4px;
  border: 1px solid #c96161;
  background-color: #f07d7d;
  outline: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-left: 12px;
  padding-top: 5px;
  font-size: 13px;
  font-weight: 400;
  color: white;
}





.textarea-meeting-hidden {
  min-height: 60px;
  width: 90%;
  border-radius: 8px;
  border: 1px solid lightgray;
  outline: none;
  margin-top: 10px;
  /* margin-bottom: 50px; */
  padding: 10px;
  font-size: 15px;
  font-weight: 100;
  color: #767676;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  flex-wrap: wrap;
  display: flex;
  overflow: scroll;
}

.textarea-meeting {
  height: 60px;
  width: 90%;
  border-radius: 8px;
  border: 1px solid lightgray;
  outline: none;
  margin-top: 10px;
  margin-bottom: 50px;
  padding: 10px;
  font-size: 15px;
  font-weight: 100;
  color: #767676;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.guestList-invitee-input {
  border: none;
  outline: none;
  overflow: hidden;
  height: 20px;
}

.invitee-list-container {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
  /* height: 50px; */
  overflow: auto;
}
.email-delete-container {
  display: flex;
  margin-right: 10px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #c2d9fa;
  margin-bottom: 5px;
  overflow: auto;
  /* padding: 3px; */
  font-size: 13px;
  font-weight: 600;
  background-color: #d6e7fe;
  color: black;
}

.list-meeting {
  /* background-color: #d6e7fe; */
  opacity: 0.8;
  padding-left: 8px;
  padding-top: 2px;
}

/* .list-meeting-toggle{
    background-color: none;
    padding: 3px;
    border: none;
} */

.delete-button-meeting {
  background-color: #d6e7fe;
  opacity: 0.8;
  color: black;
  border: none;
  outline: none;
  cursor: pointer;
}

.error-list-meeting {
  background-color: rgb(251, 57, 57);
  opacity: 0.8;
  color: white;
  padding: 3px;
  border: none;
  outline: none;
}

.error-delete-button-meeting {
  background-color: rgb(251, 57, 57);
  opacity: 0.8;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
}

.schedule-event-button {
  height: 45px;
  width: 147px;
  border-radius: 30px;
  outline: none;
  /* border: 1px solid #1c78fb;
    background-color: #1c78fb; */
  border: 1px solid #27259c;
  background-color: #27259c;
  color: white;
  cursor: pointer;
  margin-bottom: 30px;
  font-size: 15px;
  padding-bottom: 5px;
}
.display-none {
  display: none;
}

.show-div {
  background-color: white;
}

.p-meeting {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 25px;
}
.pay-ment-form{
  width: 90%;
  padding: 2rem;
  box-shadow: 0px 0px 23px 0px rgba(211,211,219,0.75);
  -webkit-box-shadow: 0px 0px 23px 0px rgba(211,211,219,0.75);
  -moz-box-shadow: 0px 0px 23px 0px rgba(211,211,219,0.75);
}
.pay-button{
  padding-top: 2rem !important;
}
@media only screen and (max-width: 600px) {
  .outerdiv-meeting {
    width: 95%;
    height: 100%;
    margin-bottom: 5rem;
    display: grid;
    grid-template-columns: 1fr;
  
  }
  
}