.create-edit-form {
  box-shadow: 0px 0px 23px 0px rgba(211, 211, 219, 0.75);
  -webkit-box-shadow: 0px 0px 23px 0px rgba(211, 211, 219, 0.75);
  -moz-box-shadow: 0px 0px 23px 0px rgba(211, 211, 219, 0.75);
  padding: 1rem;
}
.meeting-select-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100% !important;
  padding-bottom: 2rem !important;
}
.meeting-select-wrap-top {
    width: 98%;
    display: grid;
    margin: 0.3rem;
    grid-template-columns: 49% 21% 30%;
    grid-gap: 0rem;
    place-items: center;
  }
  .meeting-select-wrap-bottom{
    width: 98%;
    display: grid;
    margin: 0.3rem;
    grid-template-columns: 70% 30%;
    grid-gap: 0rem;
    place-items: center;
    
  }
.meeting-select-wrap-calender {
  background-color: #fff;
  padding-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meeting-schudule-submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.expert-dashboard-navigation {
  margin-top: 1rem;
}

@media only screen and (max-width: 479px) {
.meeting-select-wrap-top-info{
    font-size: 1.2rem;

}
.meeting-select-wrap-bottom{
    max-height: 400px;
    align-items: flex-start;
}
}
