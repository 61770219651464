.expert-dashboard {
  margin: 2rem;
  display: grid;
  grid-template-columns: 25% 73%;
  grid-gap: 2rem;
  @media #{$small-tablet} {
    grid-template-columns: 29% 69%;
  }
  @media #{$sm-layout} {
    grid-template-columns: 99%;
  }
  .expert-dashboard-nav {
    background-color: #ffffffff;
    border: 1px solid var(--color-border);
    height: auto;
    box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
    -webkit-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
    -moz-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
    @media #{$sm-layout} {
      display: none;
    }
    .expert-dashboard-nav-top {
      margin-top: 0.5rem;
      margin-bottom: -1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .expert-dashboard-img-wrap {
        margin-top: 1rem;
        width: 15rem;
        height: 15rem;
        border-radius: 50%;
        @media #{$large-mobile} {
          width: 12rem;
          height: 12rem;
        }
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
      }
      .expert-dashboard-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h6 {
          margin-bottom: -6px;
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
    }
    .expert-dashboard-nav-ul {
      display: flex;
      flex-direction: column;
      a {
        width: 100%;
        padding: 0.5rem;
        font-size: 2rem;
        transition: var(--transition);
        @media #{$large-mobile} {
          font-size: 1.6rem;
        }

        &.active {
          color: var(--color-primary);
        }

        &:hover {
          transform: scale(1.01);
          color: var(--color-primary);
        }
      }
    }
  }
  .expert-dashboard-body {
    width: 100%;
    // // background-color: rgb(235, 240, 247);
    // border: 1px solid var(--color-border);
    // @media #{$custom-lg-device} {
    //   border: 5px solid red
    // }
    // @media #{$smlg-device} {
    //   border: 5px solid green
    // }
    // @media #{$small-tablet} {
    //   border: 5px solid blue
    // }
    // @media #{$sm-layout} {
    //   border: 5px solid yellow
    // }
    // @media #{$large-mobile} {
    //   border: 5px solid rgb(0, 255, 217)
    // }
    // @media #{$small-mobile} {
    //   border: 5px solid orange
    // }
  }
}
// basic info for expert
.expert-basic-info {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  -webkit-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  -moz-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  // border: 1px solid var(--color-border);
  margin-bottom: 2rem;
  padding: 1rem;

  .expert-basic-profile {
    display: flex;
    align-items: center;

    .expert-basic-profile-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        margin: 0;
        line-height: 1;
      }
    }
    .expert-basic-proile-img {
      margin: 1rem;
      width: 10rem;
      height: 10rem;
      border-radius: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }

  button {
    outline: none;
    border: none;
    margin: 1rem 0;
    font-size: 18px;
    color: var(--color-primary);
    cursor: pointer;
    @media #{$large-mobile} {
      font-size: 16px;
    }
  }

  .form-input-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    @media #{$small-mobile} {
      flex-direction: column;
    }

    .form-input-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0.5rem 0;

      .clinic-image-wrap {
        border: 0.4px dashed;
        width: 100%;
        position: relative;
        height: 15rem;
        display: flex;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 2rem;
        justify-content: center;
        cursor: pointer;
        @media #{$large-mobile} {
          height: 12rem;
        }

        input {
          position: absolute;
          top: -5rem;
          left: 0;
          width: 250%;
          height: 35rem;
          z-index: 10;
          background-color: transparent;
        }
      }

      input {
        height: 4.5rem;
        background: #f4f5f6;
        border: 1px solid var(--color-border);
        border-radius: 5px;
      }
      select {
        height: 4.5rem;
        background: #f4f5f6;
        border: 1px solid var(--color-border);
        border-radius: 5px;
      }
      textarea {
        width: 100%;
        height: 10rem;
        font-size: 13px;
        background: #f4f5f6;
        border: 1px solid var(--color-border);
        border-radius: 5px;
      }
    }
  }
}

// appointments for Experts
.experts-appointments-tab {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  -webkit-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  -moz-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  // border: 1px solid var(--color-border);
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .experts-appointments-tab-item {
    width: 100%;
    display: flex;
    align-items: center;

    .experts-appointments-tab-img-wrap {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      //   border: 15px solid var(--color-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .experts-appointments-tab-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      P {
        line-height: 0;
      }
      h6 {
        line-height: 0;
      }
    }
  }
}
.expert-apointments-table {
  width: 100%;
  padding: 0.5rem;
  background-color: #ffffff;
  border: 1px solid var(--color-border);
  margin-bottom: 2rem;
  overflow-x: scroll !important;
  box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  -webkit-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  -moz-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  .responsive-table {
    width: 100% !important;
    min-width: 800px;
    @media #{$sm-layout} {
      width: 100% !important;
      min-width: 100%;
    }
    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
    }

    .ttbody {
      // width: 100%;
      // border-bottom: 1.3px solid rgba(11, 10, 10, 0.526);
      tr {
        // width: 100%;
        border-bottom: 1.3px solid rgba(11, 10, 10, 0.526);

        td {
          .action-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            @media #{$sm-layout} {
              // flex-direction: column !important;
            }

            .label-view {
              background-color: rgba(2, 182, 179, 0.12) !important;
              color: #1db9aa !important;
              text-align: center;
              padding: 2px 4px;
              outline: none;
              border: none;
              border-radius: 15px;
              cursor: pointer;
              @media #{$large-mobile} {
                padding: 1px;
                border-radius: 5px;
                margin: 0 2px;
              }
            }
            .label-accept {
              background-color: rgba(15, 183, 107, 0.12) !important;
              color: #26af48 !important;
              text-align: center;
              cursor: pointer;
              padding: 2px 4px;
              outline: none;
              border: none;
              border-radius: 15px;
              @media #{$large-mobile} {
                padding: 1px;
                border-radius: 5px;
                margin: 0 2px;
              }
            }
            .label-cancel {
              background-color: rgba(242, 17, 54, 0.12) !important;
              color: #e63c3c !important;
              text-align: center;
              cursor: pointer;
              padding: 2px 4px;
              outline: none;
              border: none;
              border-radius: 15px;
              @media #{$large-mobile} {
                padding: 1px;
                border-radius: 5px;
                margin: 0 2px;
              }
            }
          }
          .row-table {
            display: flex;
            align-items: center;
            p {
              align-self: center;
              margin: 0;
            }

            .expert-table-img {
              height: 4rem;
              width: 4rem;
              border-radius: 50%;
              margin: 0.3rem;

              img {
                height: 100%;
                width: 100%;
                border-radius: 50%;
              }
            }
          }

          .column-table {
            display: flex;
            flex-direction: column;
            // align-items: flex-start;
            justify-content: space-between;
            margin-top: 5px;

            p {
              line-height: 1;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

// reviews

.expert-review-card {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  -webkit-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  -moz-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  // border: 1px solid var(--color-border);
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 20% 80%;
  .expert-review-img-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .expert-review-img {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  .expert-review-content {
    display: flex;
    flex-direction: column;
    margin: 1rem;

    .expert-review-content-top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h6 {
        line-height: 0;
        margin-top: 1rem;
      }

      p {
        line-height: 0;
      }

      .expert-review-content-rigth {
        color: orange;
      }
    }
    .expert-review-content-bottom {
      margin-bottom: 0;
    }
  }
}

// Earnings

.expert-schedule {
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  -webkit-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  -moz-box-shadow: 0px 0px 5px 2px rgba(219, 209, 209, 0.75);
  // border: 1px solid var(--color-border);
  margin-bottom: 2rem;

  .expert-schedule-timings {
    display: flex;
    flex-direction: column;
    margin: 0 2rem;

    .expert-schedule-add {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        background-color: rgba(15, 183, 107, 0.12) !important;
        color: #26af48 !important;
        line-height: 1;
        margin: 0.5rem;
      }
    }

    .expert-schedule-time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;
      border-bottom: 1px solid rgba(36, 36, 36, 0.463);

      .schedule-input-box-wrap {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        select {
          height: 5rem;
          margin-bottom: 5px;
        }
      }
      .expert-schedule-buttons {
        display: flex;
        flex-direction: column;

        .btn {
          margin: 5px;
          font-size: 12px;
        }
      }
    }
  }
}
