.signup {
  /* width: 60%; */
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

#p-signup {
  font-size: 18px;
  color: rgb(100, 100, 100);
  padding-top: 10px;
  padding-bottom: 8px;
}
.inner-signup {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  text-align: center;
  justify-content: center;

  height: 100%;
  width: 90%;
  background-color: #fff !important;
  outline: none;
  border: none;
}
.sign-up-form-info {
  width: 100%;
  height: 100%;
  color: #fff !important;
  background: #385aa2;
  padding: 0.5rem;
  border-radius: 6px 0 0 6px;
}

.inner-withdraw {
  border-radius: 8px;
  text-align: left;
  margin-top: 4%;
  height: 100%;
  width: 410px;
  background-color: #fff !important;
  box-shadow: 0px 0px 23px 0px rgba(211, 211, 219, 0.75);
  -webkit-box-shadow: 0px 0px 23px 0px rgba(211, 211, 219, 0.75);
  -moz-box-shadow: 0px 0px 23px 0px rgba(211, 211, 219, 0.75);
}

#signin-Google-Button {
  margin-top: 20px;
}
#or {
  font-size: 13px;
  margin-left: 27%;
  color: lightgray;
}

#h3 {
  font-size: 15px;
  padding-top: 10px;
}

.input-signin {
  height: 40px;
  width: 90%;
  border-radius: 10px;
  border: 1px solid lightgray;
  outline: lightgray;
  font-size: 15px;
  padding-left: 15px;
  margin-bottom: 10px;
  /* background-color: rgb(220, 239, 246); */
}

#input::placeholder {
  color: lightgray;
  font-weight: 100;
}

#button-signup {
  height: 45px;
  width: 110px;
  /* background-color: rgb(0, 107, 255); */
  background-color: #27259c;
  color: white;
  border-radius: 22px;
  outline: none;
  /* border: 1px solid rgb(0, 107, 255); */
  border: 1px solid #27259c;
  /* font-weight:100; */
  cursor: pointer;
  margin-top: 4%;
  margin-right: 5%;
  font-size: 15px;
  /* align-items:flex-end;    */
  float: right;
}

.existing-account {
  font-size: 14px;
  /* padding-bottom: 20px; */
  color: rgb(100, 100, 100);
}

#a-signup {
  /* color: rgb(0, 107, 255); */
  color: #27259c;
}
.sign-up-form-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}
@media only screen and (max-width: 767px) {
  .signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .inner-signup {
    border-radius: 6px;
    margin-top: 4%;
    height: 100%;
    width: 95% !important;
  }
  .sign-up-form-wrap {
    display: flex;
    flex-direction: column-reverse;
  }
  .sign-up-form-info {
    border-radius: 0 0 6px 6px;
  }
}
