/*--------------------------
    Header Styles  
--------------------------*/

.edu-header {
    .logo {
        a {
            display: block;
            height: 100%;
            
            transition: min-height 1s ease !important;
            img {
                max-height: 55px;
                @media #{$sm-layout} {
                max-height: 35px;
                }
            }
          
        }
    }

    &.disable-transparent {
        .logo {
            a {
                height: 100%;
            }
        }
    }

    &.header-relative {
        position: relative;
    }

    &.header-transparent {
        position: absolute;
        left: 0;
        right: 0;
        width: auto;
        background: transparent;
        backdrop-filter: inherit;
        z-index: 9;
    }

    &.header-sticky {
        transition: height .5s ease-in;
        max-width: 100%;
        z-index: 99;
        &.sticky {
            position: fixed;
            top: 0;
            left: 0;
            background-color: var(--color-white) !important;
            width: 100%;
            height: 85px;
            animation: headerSticky .95s ease forwards;
            box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.05);
            z-index: 10;
            .logo {
                a {
                    height: 100%;
                }
            }
        }
    }
}


/*------------------------
    Header Style One  
--------------------------*/
    
.header-default {
    max-width: 100%;
    background-color: var(--color-white);
    color: #fff;
    font-size: 12px;
    z-index: 99;
    position: relative;
   
    .header-right {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

/*------------------------
    Header Style Two  
--------------------------*/
.header-style-2 {
    // padding: 5px 200px;
    
    @media #{$laptop-device} {
        // padding: 5px 50px;
        // background-color: blue !important;
    }
    @media #{$lg-layout} {
        // padding: 10px 30px;
        // background-color: red !important;
    }

    @media #{$md-layout} {
        // padding: 10px 30px;
        // background-color: yellow !important;
    }

    @media #{$sm-layout} {
        // padding: 10px 15px;
        // background-color: green !important;
    }

    .header-menu-bar {
        display: flex;
        align-items: center;
    }
}

.white-box-icon {
    height: 50px;
    line-height: 50px;
    width: 50px;
    text-align: center;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    outline: none;
    color: var(--color-primary);
    border: 0 none;
    padding: 0;
    background: var(--color-white);
    display: block;
    margin-left: 4px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
    &:hover{
        background: var(--color-primary);
        color: var(--color-white); 
    }
    @media #{$sm-layout} {
        height: 40px;
        line-height: 40px;
        width: 40px;
    }

    &.header-search,
    &.header-menu {
        font-size: 16px;
    }
}




@keyframes headerSticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}