.new-footer-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  background: #00008b;

  .new-footer-wrap {
    display: flex;
    flex-direction: column;

    .new-footer-top-content {
      display: grid;
      grid-template-columns: 3fr 2fr 2fr;
      gap: 60px;
    }

    .new-footer-top-left {
      display: flex;
      flex-direction: column;
      gap: 20px;

      img {
        width: 20%;
        height: auto;
      }

      p {
        color: #fff;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .new-footer-bottom-social {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          color: #f5bd00;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 6.907px;
        }
      }
    }

    .new-footer-top-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h5 {
        color: #fff;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 5px;

        a {
          color: #fff;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .new-footer-bottom-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2rem;

      p {
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
