.new-for-organization-section {
  width: 100vw;
  display: flex;
  align-items: center;
  background: #00008b;
  padding-top: 100px;
  padding-bottom: 20px;

  .new-for-organization-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 60px */
      background: linear-gradient(211deg, #f5bd00 0%, #fff 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      width: 60%;
      color: #fff;
      text-align: center;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 45.5px */
      letter-spacing: 1.4px;
    }
    .new-for-organization-cards-section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
