.how-it-works-wrap {
  padding-top: 10rem;
}
.how-it-works {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 3rem;
  overflow-x: scroll;
  /* hide scroll bar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

}
.how-it-works-card {
width: 100%;
  min-width: 340px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  transition: 0.3s;
  color: var(--color-primary);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}
@media only screen and (max-width: 780px) {
  .how-it-works{
    grid-template-columns: 1fr 1fr;
  }
  .how-it-works-wrap {
    padding-top: 10rem;
  }
}
@media only screen and (max-width: 600px) {
  .how-it-works-wrap {
    padding-top: 6rem;
  }
  .how-it-works {
    display: grid;
    grid-template-columns: 1fr;
    min-width: 100%;
    place-items: center;
    gap: 1rem;
  }
  .how-it-works-card {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
  }
}
