.founder-main-wrap {
  background-color: #26206d;
  overflow: hidden;
  width: 100%;
  position: relative;
  opacity: 1;
  height: 100%;
}
.founder-wrap {
  height: 90vh;
  width: 100%;
  padding-top: 90px;
}
.video-wrapper {
  transform: rotate(0deg);
  top: 0%;
  left: -1.93387066%;
  position: absolute;
  height: 116.99096728%;
  opacity: 0.3;
  transform: scale(-1, 1);
}
.video-container {
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
}
.founder-read-more-btn {
  display: flex;
}
.founder-btn1 {
  border-radius: 2px;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 700;
  transition: 0.4s;
  font-family: var(--font-primary);
  margin-right: 2rem;
  margin-bottom: 2rem;
  background-color: #fff;
  color: #26206d;
  border: 4px solid #fff;
}
.founder-btn1:hover {
  background-color: transparent;
  color: #fff;
}
.founder-btn2 {
  border-radius: 2px;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  color: #fff;
  background: transparent;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 700;
  transition: 0.4s;
  font-family: var(--font-primary);
  border: 4px solid #fff;
}
.founder-btn2:hover {
  background-color: #fff;
  color: #26206d;
}
/* empower */
.empowering {
  margin: 0.4rem 0;
  display: grid;
  place-items: center;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
}
.empowering h4 {
  text-transform: none;
  font-family: "Lobster Two", cursive;
  color: #26206d;
  letter-spacing: 0em;
  font-family: cursive;
  font-style: italic;
  line-height: 1.2em;
}
.empower-img-wrap {
  transform: scale(-1, 1);
}
.empower-img-wrap img {
  display: block;
  object-fit: fill;
  width: 100%;
  height: auto;
  border-radius: 5px 25px 25px 5px;
  opacity: 1;
}
/* benefits */
.founders-benefits {
  width: 100%;
  background: #26206d;
  height: 100%;
}
.founders-benefits h4 {
  text-transform: none;
  color: #fff;
  letter-spacing: 0em;
  font-family: "Lobster Two", cursive;
  font-style: italic;
  line-height: 1.2em;
}
.founders-benefits p {
  text-transform: none;
  color: #fff;
  font-size: 2rem;
  letter-spacing: 0em;
  line-height: 1.2em;
}
.benefit-card-wrapper {
  display: grid;
  /* place-items: center; */
  gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.benefit-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.benefit-card img {
  border-radius: 5px;
}
.benefit-card h6 {
  color: #fff;
}
/* join us */
.join-us-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.join-us-content h4 {
  text-transform: none;
  color: #26206d;
  letter-spacing: 0em;
  text-align: center;
  font-family: "Lobster Two", cursive;
  font-style: italic;
  line-height: 1.2em;
}
.founder-btn3 {
  border-radius: 2px;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 700;
  transition: 0.4s;
  font-family: var(--font-primary);
  margin-right: 2rem;
  margin-bottom: 2rem;
  background-color: #26206d;
  color: #fff;
  border: 4px solid #26206d;
}
.founder-btn3:hover {
  background-color: #fff;
  color: #26206d;
}
.founder-btn4 {
  border-radius: 2px;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  color: #26206d;
  background: #fff;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 700;
  transition: 0.4s;
  font-family: var(--font-primary);
  border: 4px solid #26206d;
}
.founder-btn4:hover {
  background-color: #26206d;
  color: #fff;
}
.join-us-img {
  /* transform: scale(1, 1); */
  transform: translateY(-30%);
  overflow: hidden;
  width: 100%;
  position: relative;
  height: auto;
}
.join-us-img-wrap {
  top: 0%;
  left: 0%;
  width: 100%;
  opacity: 1;
}
.join-us-img-wrap img {
  display: block;
  object-fit: cover;
  width: 40%;
  height: auto;
}
/* helping starts */
.helping-founders {
  width: 100%;
  background: #26206d;
  height: 100%;
}
.helping-founder-wrap {
  display: grid;
  gap: 2rem;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
}
.helping-founder-wrap div {
  border: 5px solid #fff;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.helping-founder-wrap div p {
  color: #fff;
  font-size: 3.5rem;
  text-align: center;
  line-height: 1.4em;
  font-style: italic;
  font-weight: normal;
}
.helping-read-more-btn {
  display: flex;
  margin-top: 4rem;
}
.helping-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.helping-wrap h4 {
  color: #fff;
  text-align: center;
}
.helping-wrap h6 {
  color: #fff;
  text-align: center;
}
.founder-btn5 {
  border-radius: 2px;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 700;
  transition: 0.4s;
  font-family: var(--font-primary);
  margin-right: 2rem;
  margin-bottom: 2rem;
  background-color: #fff;
  color: #26206d;
  border: 4px solid #fff;
}
.founder-btn5:hover {
  background-color: transparent;
  color: #fff;
}
.founder-btn6 {
  border-radius: 2px;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  color: #fff;
  background: transparent;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 700;
  transition: 0.4s;
  font-family: var(--font-primary);
  border: 4px solid #fff;
}
.founder-btn6:hover {
  background-color: #fff;
  color: #26206d;
}

/* responsive */
@media only screen and (max-width: 991px) {
}
@media only screen and (max-width: 767px) {
  .benefit-card-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .helping-founder-wrap {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 575px) {
  .empowering {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  .empower-img-wrap {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .empower-img-wrap img {
    width: 50%;
  }
  .benefit-card-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .helping-founder-wrap {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 479px) {
  .benefit-card-wrapper {
    grid-template-columns: 1fr;
  }
  .helping-founder-wrap {
    grid-template-columns: 1fr;
  }
}
