.newpage-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.748) 0%,
    rgba(248, 248, 248, 0.4) 70%,
    rgba(0, 0, 139, 0.318) 100%
  );

  .newpage-navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .newpage-navbar-logo {
      display: flex;
      align-items: center;

      img {
        width: 80px;
        height: auto;
      }
    }

    .newpage-navbar-menu {
      display: flex;
      align-items: center;
      gap: 3rem;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      color: #00008b;
      text-decoration: none;

      .new-header-btn {
        display: inline-flex;
        padding: 18px 47px;
        justify-content: center;
        align-items: center;
        border: 4px solid #00008b;
        background: #f5bd00;
        font-size: 18px !important;
      }
    }
  }
}
