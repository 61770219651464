/*--------------------------
    Testimonial Area  
----------------------------*/
.satisfied-learner {
  padding-right: 70px;
  position: relative;

  @media #{$lg-layout} {
    padding-right: 0;
  }

  @media #{$md-layout} {
    padding-right: 0;
  }

  @media #{$sm-layout} {
    padding-right: 0;
  }

  .thumbnail {
    img {
      width: 100%;
      border-radius: 5px;
    }
  }
  .trophy-content {
    width: 235px;
    background: var(--color-white);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.06);
    display: flex;
    border-radius: 5px;
    padding: 20px 25px;
    align-items: center;
    position: absolute;
    top: 97px;
    left: -164px;

    @media #{$lg-layout} {
      top: auto;
      left: 10px;
      bottom: 10px;
    }

    @media #{$md-layout} {
      top: auto;
      left: 10px;
      bottom: 10px;
    }

    @media #{$sm-layout} {
      top: auto;
      left: 10px;
      bottom: 10px;
    }

    .icon {
      min-width: 49px;
      min-height: 49px;
      object-fit: cover;
      margin-right: 20px;
    }

    .content {
      .text {
        font-weight: 700;
        font-size: 20px;
        line-height: 34px;
        margin-bottom: 0;
      }

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.testimonial-style-1 {
  margin-top: 25px;
  @media #{$smlg-device} {
    margin-top: 0px;
  }
  &.edu-slick-button.slick-button-left .slick-dots {
    @media #{$lg-layout} {
      bottom: -10px;
    }
  }
  .single-testimonial {
    .inner {
      .quote-sign {
        margin-bottom: 50px;
        display: inline-block;
        @media #{$lg-layout} {
          margin-bottom: 20px;
        }
        @media #{$small-tablet} {
          margin-bottom: 20px;
          margin-top: 20px;
        }
      }

      .description {
        margin-bottom: 50px;
        font-weight: 500;
        font-size: 24px;
        line-height: 1.5;
        @media #{$small-tablet} {
          margin-bottom: 30px;
        }
        @media #{$sm-layout} {
          margin-bottom: 30px;
        }
      }

      .client-information {
        display: flex;
        align-items: center;

        .thumb {
          margin-right: 20px;
          min-width: 80px;

          img {
            width: 80px;
            height: 80px;
            border-radius: 100%;
            object-fit: cover;
          }
        }

        .info {
          .title {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 5px;
          }

          .designation {
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: var(--color-primary);
          }
        }
      }
    }
  }
}

/*-----------------------------
    Testimonial Card Layout  
------------------------------*/

.testimonial-card-box {
  padding: 15px;

  .inner {
    position: relative;
    background: var(--color-white);
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 40px;

    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 33px 0 0 27px;
      border-color: transparent transparent transparent var(--color-white);
      top: calc(99% - 1px);
      transform: rotate(90deg);
      z-index: 2;
      border-bottom-right-radius: 4px;
    }

    .description {
      margin-bottom: 22px;
    }

    .client-info {
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      .thumbnail {
        margin-right: 15px;
        img {
          max-height: 55px;
          height: 55px;
          object-fit: cover;
          border-radius: 3px;
        }
      }
      .content {
        .title {
          margin-bottom: 1px;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          @media #{$sm-layout} {
            font-size: 18px;
            line-height: 29px;
          }
        }
        .designation {
          color: var(--color-primary);
          font-weight: 500;
        }
      }
    }
  }

  &.variation-2 {
    @media #{$md-layout} {
      padding: 0;
    }
    @media #{$sm-layout} {
      padding: 0;
    }
    .inner {
      padding: 50px;
      margin-bottom: 0;
      @media #{$sm-layout} {
        padding: 50px 30px;
      }
      &::after {
        display: none;
      }
      .rating {
        margin-bottom: 40px;
        @media #{$sm-layout} {
          margin-bottom: 20px;
        }
      }
      .description {
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        @media #{$sm-layout} {
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 28px;
        }
      }
      .client-info {
        margin-bottom: 0;

        @media #{$small-mobile} {
          display: block;
        }
        .thumbnail {
          margin-right: 20px;

          @media #{$small-mobile} {
            margin-right: 0;
            margin-bottom: 20px;
          }

          img {
            max-height: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 100%;
          }
        }
      }
    }
  }

  &.variation-3 {
    padding: 0;
    .description {
      margin-bottom: 64px;
      font-size: 20px;
      line-height: 32px;
      font-weight: 400;
      @media #{$sm-layout} {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 28px;
      }
    }
    .client-info {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      .thumbnail {
        margin-right: 15px;
        img {
          max-height: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 100%;
        }
      }
      .content {
        .title {
          margin-bottom: 2px;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
        }
        .designation {
          color: var(--color-primary);
          font-weight: 500;
        }
      }
    }
  }
}

.testimonial-style-5 {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    background: var(--color-primary);
    width: 610px;
    top: 0;
    height: 100%;
    left: 0;
    @media #{$custom-lg-device-two} {
      width: 29%;
    }
    @media #{$laptop-device} {
      width: 27%;
    }
    @media #{$lg-layout} {
      width: 29%;
    }
    @media #{$md-layout} {
      display: none;
    }
    @media #{$sm-layout} {
      display: none;
    }
  }
  .banner-thumbnail {
    display: inline-block;
    position: relative;
    z-index: 1;
    &::after {
      border: 5px solid rgba(251, 124, 86, 0.5);
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 20px;
      border-radius: 250px;
      z-index: -1;
      @media #{$sm-layout} {
        display: none;
      }
    }
    img {
      border-radius: 250px;
    }
  }
  .content-wrapper {
    position: relative;
    z-index: 1;
    .comment-images {
      position: absolute;
      right: 0;
      z-index: -1;
      bottom: -8%;
    }
  }
  .main-image {
    position: absolute;
    left: 0;
    top: 0;
  }
}

/*--------------------------
    Testimonial Style Two  
----------------------------*/

.testimonial-nav-content {
  .description {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  .client-info {
    margin-bottom: 40px;
    @media #{$md-layout} {
      margin-bottom: 10px;
    }
    @media #{$sm-layout} {
      margin-bottom: 10px;
    }
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 0;
    }

    .designation {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: var(--color-primary);
    }
  }
}

.testimonial-nav-wrapper {
  max-width: 264px;
}

.testimonial-nav-button {
  .single-thumbnail {
    padding: 5px;
    border: 5px solid transparent;
    border-radius: 100%;
    transition: 0.4s;
    position: relative;
    img {
      max-width: 68px;
      width: 68px;
      display: inline-block;
      height: 68px;
      border: 8px solid #fff;
      border-radius: 100%;
      cursor: pointer;
    }
  }
  .slick-current {
    &.slick-active {
      .single-thumbnail {
        .loader-container {
          opacity: 1;
        }
      }
    }
  }
}

.loader-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  .circle-loader-wrap {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(82, 95, 225, 0.2);
    border-radius: 100%;
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);

    &:after {
      content: "";
      position: absolute;
      left: 15px;
      top: 15px;
      width: auto;
      height: auto;
      border-radius: 50%;
      background-color: white;
    }

    div {
      overflow: hidden;
      position: absolute;
      width: 50%;
      height: 100%;
    }

    .loader {
      position: absolute;
      left: 100%;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 1000px;
      background-color: var(--color-primary);
    }

    .left-wrap {
      left: 0;

      .loader {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        transform-origin: 0 50% 0;
        -webkit-transform-origin: 0 50% 0;
        animation: loading-left 4s infinite linear;
        -webkit-animation: loading-left 4s infinite linear;
      }
    }

    .right-wrap {
      left: 50%;

      .loader {
        left: -100%;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        transform-origin: 100% 50% 0;
        -webkit-transform-origin: 100% 50% 0;
        animation: loading-right 4s infinite linear;
        -webkit-animation: loading-right 4s infinite linear;
      }

      @keyframes loading-left {
        0% {
          transform: rotate(0deg);
        }

        25% {
          transform: rotate(180deg);
        }

        50% {
          transform: rotate(180deg);
        }

        75% {
          transform: rotate(180deg);
        }

        100% {
          transform: rotate(180deg);
        }
      }

      @-webkit-keyframes loading-left {
        0% {
          -webkit-transform: rotate(0deg);
        }

        25% {
          -webkit-transform: rotate(180deg);
        }

        50% {
          -webkit-transform: rotate(180deg);
        }

        75% {
          -webkit-transform: rotate(180deg);
        }

        100% {
          -webkit-transform: rotate(180deg);
        }
      }

      @keyframes loading-right {
        0% {
          transform: rotate(0deg);
        }

        25% {
          transform: rotate(0deg);
        }

        50% {
          transform: rotate(180deg);
        }

        75% {
          transform: rotate(180deg);
        }

        100% {
          transform: rotate(180deg);
        }
      }

      @-webkit-keyframes loading-right {
        0% {
          -webkit-transform: rotate(0deg);
        }

        25% {
          -webkit-transform: rotate(0deg);
        }

        50% {
          -webkit-transform: rotate(180deg);
        }

        75% {
          -webkit-transform: rotate(180deg);
        }

        100% {
          -webkit-transform: rotate(180deg);
        }
      }
    }
  }
}
