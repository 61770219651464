.new-connect-section {
  background: #00008b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 44px;

  .new-connect-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      width: 80%;
      text-align: center;
      color: #fff;
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 50px */
      padding-bottom: 80px;
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 39px */
      letter-spacing: 1.2px;
    }

    .new-connect-cards-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 80px;
      padding-bottom: 80px;

      .new-connect-card {
        max-width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        background: #fff;
        width: 350.002px;
        height: 225.344px;
        flex-shrink: 0;

        h5 {
          color: #00008b;
          font-size: 24px;
          font-weight: 600;
        }
      }
    }

    a {
      border: 4px solid #fff;
      background: #f5bd00;
      display: inline-flex;
      padding: 18px 47px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #00008b;
      font-size: 30px;
      font-weight: 600;
    }
  }
}
