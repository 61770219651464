.new-partners-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  padding-bottom: 150px;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);

  .new-partners-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      background: linear-gradient(211deg, #00008b 0%, #f7d101 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .new-partners-logos {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 30px;
      overflow-x: scroll;
      scrollbar-color: transparent;

      .new-partners-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
      }
    }
  }
}
