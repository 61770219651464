.main-div-timebar {
  margin-top: 30px;
}
#h4-date {
  /* color: gray; */
  color: #27259c;
  font-size: 16px;
}
#h2-date {
  margin-top: -15px;
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
