@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;900&display=swap");
.banner-wrap {
  /* height: 560px; */
  height: auto;
  width: 100%;
  padding-top: 10rem;
  margin-top: 75px;
  display: grid;
  grid-template-columns: 60% 38%;
}
.main-banner {
  display: flex;
  width: 100%;
  padding-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: transparent;

  /* border-top: 1px solid lightgray; */
  /* font-family: Arial, Helvetica, sans-serif; */
  /* max-width: 450px; */
}
.banner-video-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#span-react-node {
  color: #385aa2;
}
.img {
  pointer-events: none;
  width: 60%;
  padding-top: 2rem;
  background: white !important;
  outline: none;
  border: none;
  height: auto;
  background-color: transparent;
  z-index: 2;

}

.contentb {
  /* position: absolute; */
  width: 95%;
}
.dont-break-the-banner {
  font-size: 1px !important;
  margin: 0 !important;
  padding: 0 !important;
  color: transparent;
}
#h1-banner {
  width: 100%;
  font-size: 50px;
  font-weight: 900;
}
.header-breaker{
  display: none;
}
#p-tag {
  /* width: 45%; */
  font-size: 17px;
  font-weight: 400;
}
.contentb a {
  /* position: absolute; */
  width: 60% !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-banner {
  width: 400px;
  position: relative;
  height: 60px;
  border-radius: 40px;
  border: 1px solid lightgray;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 5%;
  /* background-color: red; */
}

#input-banner {
  width: 100%;
  padding-left: 20px;
  background-color: transparent;
  /* z-index: -1; */
  float: left;
  height: inherit;
  /* background-color: green; */
  font-size: 16px;
  color: rgb(90, 90, 90);
  border: none;
  outline: none;
  cursor: text;
}

#input-banner::selection {
  background-color: transparent;
  outline-color: none;
}

#button-banner {
  position: absolute;
  background-color: black;
  color: white;
  top: 0;
  right: 0;
  display: inline;
  z-index: 2;
  border-radius: 30px;
  height: inherit;
  width: 120px;
  border: none;
  outline: none;
  cursor: pointer;
}
#message-banner {
  font-size: 16px;
  color: rgb(90, 90, 90);
  font-weight: 200;
}

@media only screen and (max-width: 767px) {
  .banner-wrap {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 5rem;
  }
  .main-banner {
    display: flex;

    width: 97%;
    position: relative;
    background-color: transparent;

    /* border-top: 1px solid lightgray; */
    /* font-family: Arial, Helvetica, sans-serif; */
    /* max-width: 450px; */
  }
  .contentb {
    /* position: absolute; */
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #h1-banner {
    width: 100%;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
  }
  .header-breaker{
    display: block;
  }
  #p-tag {
    /* width: 45%; */
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
  .contentb a {
    /* position: absolute; */
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .banner-video-wrap {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
